import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const seoPageData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wp {
          seo {
            schema {
              logo {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        allWpPage {
          nodes {
            isFrontPage
            id
            nodeType
            title
            uri
            seo {
              title
              metaDesc
              focuskw
              metaKeywords
              metaRobotsNoindex
              metaRobotsNofollow
              opengraphTitle
              opengraphDescription
              opengraphImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              twitterTitle
              twitterDescription
              opengraphImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              canonical
              cornerstone
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export { seoPageData };

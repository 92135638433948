import { seoPageData } from "../data/seo-page.data";
import { mapValuesDeep } from "deepdash/standalone";

function getCurrentRichContent(
    graphqlData,
    postId,
    richContentType = "richContent"
) {
    const allEdges = [];
    Object.values(graphqlData).forEach((postType) =>
        allEdges.push(...postType.edges)
    );

    // Get rich content of current post/page
    const edge = allEdges.find((edge) => {
        if (postId === "frontpage") {
            return edge.node.isFrontPage;
        } else {
            return edge.node.id === postId;
        }
    });

    return edge?.node.template[richContentType];
}

function getCurrentPageSeo(postId) {
    const graphqlData = seoPageData();
    const pagesSeo = graphqlData.allWpPage.nodes;

    // Get rich content of current post/page
    const node = pagesSeo.find((node) => {
        if (postId === "frontpage") {
            return node.isFrontPage;
        } else {
            return node.id === postId;
        }
    });

    // Modify JSON-LD Schema
    const schemaRaw = JSON.parse(node.seo.schema.raw);

    // Change Organization logo url
    schemaRaw["@graph"][0].logo.url =
        graphqlData.wp.seo.schema.logo.localFile.publicURL;

    // Change Organization logo content url
    schemaRaw["@graph"][0].logo.contentUrl =
        graphqlData.wp.seo.schema.logo.localFile.publicURL;

    if (typeof node !== "object") return node;

    const mappedNode = mapValuesDeep(node, (item) => {
        if (typeof item !== "string" || item.match(/^\/static\//) === null)
            return item;
        try {
            return new URL(item, process.env.GATSBY_SITE_URL).toString();
        } catch {
            return item;
        }
    });

    return mappedNode;
}

export { getCurrentRichContent, getCurrentPageSeo };
